<template>
  <div>
    <van-cell>
      <template #title>
        <div class="blue-box"></div>
        <span class="custom-title">{{title}}</span>
      </template>
    </van-cell>
        <van-tabs v-model="activeName" class="tab" title-active-color="#027eff" color="#027eff" @click="tabChange">
          <van-tab name = '0' title="问卷二维码" v-if="status == '1'"></van-tab>
          <van-tab name = '1' title="数据统计"></van-tab>
        </van-tabs>
    <div v-if="activeName == '0'">
      <div>
        <div class="write">请扫描二维码填写问卷调查表</div>
        <div id="qrCodeUrl" ref="qrCodeUrl" v-if="isAnonymous == '0'"></div>
        <van-image width="200" height="200" :src="this.realUrl" v-if="isAnonymous == '1'"/>
      </div>
    </div>
    <div v-if="activeName == '1'">
      <question-total ref="total" :useTotal="useTotal"></question-total>
<!--      <van-cell class="cell" v-if="single.typeStr != ''">-->
<!--        <div class="title">1.{{single.content}} ({{single.typeStr}})</div>-->
<!--        <div id="cake">-->
<!--          <div ref="echarts" class="chart-box"></div>-->
<!--        </div>-->
<!--      </van-cell>-->
<!--      <van-cell class="cell" v-if="multiple.typeStr != ''">-->
<!--        <div class="title">2.{{multiple.content}} ({{multiple.typeStr}})</div>-->
<!--        <div id="column">-->
<!--          <div ref="column" class="chart-box"></div>-->
<!--        </div>-->
<!--      </van-cell>-->
<!--      <van-cell class="cell" v-if="brief.typeStr != ''">-->
<!--        <div class="title">3.{{brief.content}} ({{brief.typeStr}})</div>-->
<!--        <van-button @click="checkHandle">查看详细信息</van-button>-->
<!--      </van-cell>-->
<!--      <answer-info v-if="answerInfoVisible" ref="answerInfo"></answer-info>-->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import answerInfo from './answer-info'
import * as echarts from 'echarts'
import qrCode from '../visit/code'
import QRCode from "qrcodejs2";
import {getImageStream} from "@/utils/index";
import questionTotal from "./questionTotal";
export default {
  components:{
    answerInfo,
    qrCode,
    questionTotal
  },
  data () {
    return {
      single: {
        content: '',
        typeStr: '',
        nameList: []
      },
      singleList:[],
      multipleList:[],
      multiple: {
        content: '',
        typeStr: '',
        nameList: []
      },
      brief: {
        content: '',
        typeStr: ''
      },
      id: 0,
      isAnonymous: '',
      status: '',
      participate: '',
      url: '',
      realUrl: '',
      useTotal: '',
      activeName: '0',
      chartLine: null,
      columnLine: null,
      answerInfoVisible: false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.isAnonymous = this.$route.query.isAnonymous
    this.status = this.$route.query.status
    this.participate = this.$route.query.participate
    this.useTotal = this.$route.query.useTotal
    this.title = this.$route.query.shortName
    if (this.isAnonymous == '0') {
      this.url = this.$url + 'questionWrite?id=' + this.id + '&isAnonymous=' + this.isAnonymous + '&status=' + this.status + '&participate=' + this.participate
      if (this.$globalData.domain) {
        this.url += '&domain=' + encodeURIComponent(this.$globalData.domain)
      }
      this.creatQrCode()
    } else {
      this.getCode()
      this.url = this.realUrl
    }
    console.log(this.url)
  },
  mounted() {
    if (this.status == 2) {
      this.$nextTick(function () {
        setTimeout(() => {
          this.$refs.total.initChart()
        }, 500)
      })
    }
  },
  activated () {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartBar) {
      this.chartBar.resize()
    }
  },
  methods: {
    checkHandle() {
      this.answerInfoVisible = true
      let id = this.id
      this.$nextTick(() => {
        this.$refs.answerInfo.init(id)
      })
    },
    tabChange(value) {
      if(value == '1') {
        this.$nextTick(function () {
          setTimeout(() => {
            this.$refs.total.initChart()
            // this.$refs.total.initColumn()
          }, 500)
        })
      } else if(value == '0'){
        this.creatQrCode()
      }
    },
    creatQrCode() {
      this.$nextTick(function () {
        setTimeout(() => {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        // text: this.url,
        text: this.url,
        // text: this.url + 'survey',
        // text: 'http://192.168.103/wggg/#/survey',
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
          })
          console.log(qrcode)
        }, 500);
      })
    },
    getCode() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/questionnaire/getQuestionnaireQrCode'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.realUrl = getImageStream(data.relativePath)
          console.log(this.realUrl)
        }
      })
    },
    // getInfo() {
    //   this.$http({
    //     url: this.$http.adornUrl('/wxapp/questionnaire/statistics'),
    //     method: 'post',
    //     params: this.$http.adornParams({
    //       id: this.id,
    //       orgId: this.$orgId
    //     },false)
    //   }).then(({data}) => {
    //     if (data.code == 0) {
    //       data.list.map(item => {
    //         if (item.type === '1') {
    //           this.single.content = item.content
    //           this.single.typeStr = item.typeStr
    //         } else if (item.type === '2') {
    //           this.multiple.content = item.content
    //           this.multiple.typeStr = item.typeStr
    //         } else if (item.type === '0') {
    //           this.brief.content = item.content
    //           this.brief.typeStr = item.typeStr
    //         }
    //       })
    //     } else {
    //       this.$toast.fail(data.msg);
    //     }
    //   })
    // },
    // resize(val) {
    //   let nowClientWidth = document.documentElement.clientWidth
    //   return val * (nowClientWidth / 1920)
    // },
    // initChart() {
    //   this.$http({
    //     url: this.$http.adornUrl('/wxapp/questionnaire/statistics'),
    //     method: 'post',
    //     params: this.$http.adornParams({
    //       id: this.id,
    //       orgId: this.$orgId
    //     },false)
    //   }).then(({data}) => {
    //     if (data.code == 0) {
    //       data.list.map(item => {
    //         if (item.type === '1') {
    //           item.statistics.map(items => {
    //             this.single.nameList.push(items.name)
    //             this.singleList.push({name: items.name, value: items.value})
    //           })
    //         }
    //       })
    //       // setTimeout( () => {
    //       var option = {
    //         tooltip: {
    //           trigger: 'item',
    //           formatter: '{b}: {c} ({d}%)'
    //         },
    //         legend: {
    //           icon: 'rect',
    //           itemWidth: this.resize(50),
    //           itemHeight: this.resize(50),
    //           itemGap: this.resize(120),
    //           bottom: 'bottom',
    //           itemStyle: {
    //             borderCap: 'square'
    //           }
    //         },
    //         color: ['#3780f4', '#ff7b45', '#ffc63c', '#36d1c9'],
    //         series: [
    //           {
    //             name: this.single.nameList,
    //             type: 'pie',
    //             radius: '50%',
    //             data: this.singleList,
    //             label: {
    //               // normal: {
    //               //   show: true,
    //               //   formatter: (e) => {
    //               //     let value
    //               //     this.singleList.filter(item => {
    //               //       if (item.name === e.name) {
    //               //         value = item.value
    //               //       }
    //               //     })
    //               //     return (value / 100) + '%'
    //               //   }
    //               // }
    //               // normal:{
    //                 label:{
    //                   show: true,
    //                   formatter: '{b}:{c}({d}%)'
    //                 },
    //                 labelLine :{show:true},
    //               // }
    //             },
    //             emphasis: {
    //               itemStyle: {
    //                 shadowBlur: 10,
    //                 shadowOffsetX: 0,
    //                 shadowColor: 'rgba(0, 0, 0, 0.5)'
    //               }
    //             },
    //             labelLine: { // 数据引导线
    //               show: true,
    //               length: this.resize(80),
    //               length2: this.resize(40),
    //               smooth: true
    //             },
    //           }
    //         ]
    //       }
    //         this.chartLine = echarts.init(this.$refs.echarts)
    //         this.chartLine.setOption(option)
    //       // }, 100)
    //       window.addEventListener('resize', () => {
    //         if (this.chartBar) {
    //           this.chartBar.resize()
    //         }
    //       })
    //     } else {
    //       this.$toast.fail(data.msg);
    //     }
    //   })
    //
    // },
    // initColumn() {
    //   this.$http({
    //     url: this.$http.adornUrl('/wxapp/questionnaire/statistics'),
    //     method: 'post',
    //     params: this.$http.adornParams({
    //       id: this.id,
    //       orgId: this.$orgId
    //     },false)
    //   }).then(({data}) => {
    //     if (data.code == 0) {
    //       data.list.map(item => {
    //         if (item.type === '2') {
    //           item.statistics.map(items => {
    //             this.multiple.nameList = items.xdata
    //             this.multipleList = items.ydata
    //           })
    //         }
    //       })
    //       setTimeout(() => {
    //       var options = {
    //         tooltip: {
    //           trigger: 'axis',
    //           axisPointer: {
    //             type: 'shadow'
    //           },
    //           formatter: '{b}:{c}'
    //         },
    //         grid: {
    //           left: '3%',
    //           right: '4%',
    //           bottom: '3%',
    //           containLabel: true
    //         },
    //         xAxis: {
    //           type: 'value',
    //           boundaryGap: [0, 0.1],
    //           min: 0,
    //           max: 40,
    //           splitNumber: 5,
    //           axisLine: {
    //             show: true,
    //             lineStyle: {
    //               color: ['#c1d0e0']
    //             }
    //           },
    //           axisLabel: {
    //             textStyle: {
    //               color: '#000'
    //             }
    //           }
    //         },
    //         yAxis: {
    //           type: 'category',
    //           splitNumber: 5,
    //           data: this.multipleList,
    //           axisLine: {
    //             show: true,
    //             lineStyle: {
    //               color: ['#c1d0e0']
    //             }
    //           },
    //           axisLabel: {
    //             textStyle: {
    //               color: '#000'
    //             }
    //           }
    //         },
    //         series: [
    //           {
    //             name: this.multipleList,
    //             type: 'bar',
    //             data: this.multiple.nameList,
    //             itemStyle: {
    //               normal: {
    //                 color: function (params) {
    //                   var colorList = ['#ff7b45', '#ffc63c', '#36d1c9', '#3780f4'];
    //                   return colorList[params.dataIndex]
    //                 },
    //                 label: {
    //                   show: true,  //开启显示
    //                   formatter: '{c}',
    //                   position: 'right',
    //                   textStyle: {  //数值样式
    //                     color: 'black',
    //                     fontSize: this.resize(60)
    //                   },
    //                 }
    //               }
    //             }
    //           },
    //         ]
    //       }
    //         this.columnLine = echarts.init(this.$refs.column)
    //         this.columnLine.setOption(options)
    //       },1)
    //       window.addEventListener('resize', () => {
    //         if (this.chartBar) {
    //           this.chartBar.resize()
    //         }
    //       })
    //     } else {
    //       this.$toast.fail(data.msg);
    //     }
    //   })
    // }
  }
}
</script>
<!--<style>-->
<!--.notice .van-field__control{-->
<!--  border: 2px solid #eee;-->
<!--  border-radius: 10px;-->
<!--  padding-left: 15px;-->
<!--  padding-top: 5px;-->
<!--}-->
<!--</style>-->
<style lang="scss" scoped>
#cake {
  position: relative;
  width: 750px;
  height: 500px;

  .chart-box {
    height: 100%;
    z-index: 999;
    margin-right: 50px;
  }
}
  #column {
    position: relative;
    width: 750px;
    height: 500px;
    .chart-box {
      height: 100%;
      z-index: 999;
      margin-right: 50px;
    }
  }
  .cell {
    .van-button {
      height: 70px;
      color: #367ff3;
      border: 1px solid #397ff3;
      background-color: #ebf2fd;
      border-radius: 10px;
      margin-top: 20px;
    }
  }
#qrCodeUrl {
  display: inline-block;
  margin: 26%;
  //margin-left: 20%;
  //margin-top: 30%;
  img {
    position:absolute;
    left:50%;
    top:50%;
    //width: 100px;
    //height: 100px;
    background-color: #fff; //设置白色背景色
    //padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
    //margin-left: 50%;
  }
}

.write {
  position:absolute;
  left:25%;
  top: 300px;
  font-size: 30px;
  color: #00a2d3;
}
.van-image {
  margin: 26%;
  //width: 100px;
  //height: 100px;
  background-color: #fff; //设置白色背景色
  //padding: 6px; // 利用padding的特性，挤出白边
  box-sizing: border-box;
  //margin-left: 50%;
}
</style>
